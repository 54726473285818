import React, { useEffect, useState } from 'react';

import Footer from '../components/footer';
import Header from '../components/header';
import SEO from '../components/seo';
import Content from '../components/content';
import { Link } from 'gatsby';
// import Analytics from '../components/analytics';

import { List } from 'antd';
import { getNews } from '../api/server';

export default () => {
    const [listData, setListData] = useState([]);
    const [totalCount, setTotal] = useState(0);
    const getPage = async (pageNo) => {
        const _res = await getNews({
            PageNo: pageNo || 1,
            PageSize: 5,
            IsPublish: true,
            OrderBy: 'id desc'
        });
        if (_res && _res.statusCode === 200) {
            const { items, totalCount } = _res.data;
            let _list = [];
            items.forEach((item) => {
                _list.push({
                    id: item.id,
                    href: '',
                    title: item.title,
                    avatar: '',
                    description: '',
                    content: item.describe,
                    img: item.picUrl
                });
            });
            setListData(_list);
            setTotal(totalCount)
        }
    };
    useEffect(() => {
        getPage();
    }, []);

    return (
        <section>
            <SEO title="新闻资讯" />
            <Header />
            <List
                split={true}
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page) => {
                        // console.log(page);
                        setListData([]);
                        getPage(page);
                    },
                    pageSize: 5,
                    total: totalCount
                }}
                style={{ margin: '20px 150px' }}
                dataSource={listData}
                footer={<div></div>}
                renderItem={(item) => (
                    <Link to={`/newsDetails#${item.id}`}>
                        <List.Item
                            key={item.title}
                            actions={[]}
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <img width={272} alt="logo" src={item.img} />
                            <Content>
                                <div
                                    style={{
                                        color: '#244ec7',
                                        fontSize: '28px',
                                        marginLeft: '20px'
                                    }}
                                >
                                    {item.title}
                                </div>
                                <div
                                    dangerouslySetInnerHTML={{ __html: item.content }}
                                    style={{
                                        margin: '40px',
                                        height: '96px',
                                        lineHeight: '16px',
                                        overflow: 'hidden',
                                        fontSize: '12px',
                                        // textOverflow: 'ellipsis'
                                    }}
                                    className="content-item"
                                ></div>
                            </Content>
                        </List.Item>
                        <div style={{ borderBottom: '1px solid #a7a7a7' }}></div>
                    </Link>
                )}
            />
            <Footer />
        </section>
    );
};
